@if (!multiselect) {
  <input
    #displayedValueInput
    [(ngModel)]="displayedValue"
    [autocomplete]="autocomplete"
    [class.open]="isOpen"
    placeholder="{{ (placeholder ? placeholder : 'common.controls.select.placeholder') | translate }}"
    type="text"
  />
}

<div
  [class.open]="isOpen"
  class="select-container"
>
  <div
    #control
    class="select_control"
  >
    @if (multiselect && !value.length) {
      <div class="select_placeholder">
        {{ (placeholder ? placeholder : 'common.controls.select.placeholder') | translate }}
      </div>
    }

    @if (multiselect && value.length) {
      <div class="select_selected-options">
        @for (selectedOpt of value; track selectedOpt) {
          <div
            [class.select_selected-option--non-removable]="selectedOpt.nonRemovable"
            class="select_selected-option"
          >
            @if (returnFullOption) {
              <span>{{ selectedOpt.label || selectedOpt.title | translate }}</span>
            }
            @if (!returnFullOption) {
              <span>{{ getLabelForValue(selectedOpt) }}</span>
            }
            @if (!selectedOpt.nonRemovable) {
              <div
                (click)="unselectOption($event, selectedOpt)"
                [appFocusable]
                class="select_remove-btn"
              >
                <img [appAssetSrc]="'assets/svg/common/cross' + (disabled ? '.svg' : '-blue.svg')" />
              </div>
            }
          </div>
        }
      </div>
    }

    <div
      (click)="toggle($event)"
      [appFocusable]
      class="select_toggle-btn"
    >
      <img appAssetSrc="assets/svg/common/arrow-down-blue.svg" />
    </div>
  </div>

  @if (isOpen) {
    <div
      #optionsArea
      [ngStyle]="{ height: getOptionsDropdownHeight() }"
      class="select_options"
    >
      <ng-scrollbar>
        @for (option of specialOptions; track option) {
          <div
            (click)="selectSpecialOption($event, option)"
            [attr.disabled]="option.disabled ? true : null"
            class="select_special-option"
          >
            {{ option.label || option?.title | translate }}
          </div>
        }
        @for (option of availableOptions; track option) {
          <div
            (click)="selectOption($event, option)"
            [attr.disabled]="option.disabled ? true : null"
            [class.selected]="isOptionSelected(option)"
            [appFocusable]
            class="select_option"
          >
            <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
          </div>
        }
        @for (group of optionGroups; track group) {
          <div class="select_group">
            <div>{{ group.label | translate }}</div>
            @for (option of group.options; track option) {
              <div
                (click)="selectOption($event, option)"
                [attr.disabled]="option.disabled ? true : null"
                [class.selected]="isOptionSelected(option)"
                [appFocusable]
                class="select_option"
              >
                <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option }"></ng-container>
              </div>
            }
          </div>
        }
      </ng-scrollbar>
      <ng-template
        #optionTemplate
        let-option
      >
        <span>{{ option.label || option?.title | translate }}</span>
        @if (option.buttons) {
          <span class="select_option-buttons">
            @for (button of option.buttons; track button) {
              <div
                (click)="optionButtonClick($event, button)"
                [appFocusable]
              >
                <img [appAssetSrc]="button.iconSrc" />
              </div>
            }
          </span>
        }
      </ng-template>
    </div>
  }
</div>
