import { CdkScrollable } from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  ViewEncapsulation,
  inject,
  output,
  AfterContentInit,
  AfterContentChecked,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbar, ScrollViewport } from 'ngx-scrollbar';

@Component({
  selector: 'scrollable-area',
  templateUrl: './scrollable-area.component.html',
  styleUrls: ['./scrollable-area.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgScrollbar, InfiniteScrollModule, CdkScrollable, ScrollViewport],
})
export class ScrollableAreaComponent implements AfterContentInit, AfterContentChecked, AfterViewInit, OnDestroy {
  private readonly elementRef = inject(ElementRef);
  @ViewChild('content', { static: true }) contentWrapper: ElementRef;
  @ViewChild('scrollbar', { static: true }) scrollbar: NgScrollbar;
  @Input() maxHeight: number | 'body';
  @Input() shrink = false;
  @Input() grow = true;
  @Input() scrollActivationPercent = 20;
  @Input() autoHeight = false;
  @Input() autoWidth = false;
  scrollDown = output<null>();
  scrollUp = output<null>();

  public scrollAreaHeight = 'auto';
  private content: any;

  constructor() {
    this.updateHeight = this.updateHeight.bind(this);
  }

  public get isScrolledDown(): boolean {
    const el = this.scrollbar.nativeElement as HTMLElement;
    if (!el) {
      return;
    }
    return el.scrollTop >= el.scrollHeight - el.clientHeight;
  }

  public ngAfterContentInit(): void {
    this.content = this.contentWrapper?.nativeElement.innerHTML;
    if (this.content) {
      this.updateHeight();
    }
  }

  public ngAfterContentChecked(): void {
    const currentContent = this.contentWrapper?.nativeElement.innerHTML;
    if (currentContent && currentContent !== this.content) {
      this.content = currentContent;
      this.updateHeight();
    }
  }

  public ngAfterViewInit(): void {
    window.addEventListener('resize', this.updateHeight);
    this.updateHeight();
  }

  public ngOnDestroy(): void {
    window.removeEventListener('resize', this.updateHeight);
  }

  public async updateHeight(): Promise<any> {
    return await new Promise((resolve) => {
      setTimeout(() => {
        this.scrollAreaHeight = this.getScrollAreaHeight();
        this.scrollbar?.update();
        resolve(this.scrollAreaHeight);
      }, 0);
    });
  }

  public getScrollAreaHeight(): string {
    if (!this.elementRef || this.autoHeight) {
      return 'auto';
    }

    const contentHeight = this.contentWrapper.nativeElement.clientHeight;
    const areaHeight = !this.elementRef.nativeElement.getBoundingClientRect().height
      ? contentHeight
      : this.elementRef.nativeElement.getBoundingClientRect().height;
    const maxHeight = this.maxHeight
      ? this.maxHeight === 'body'
        ? document.body.clientHeight - 40
        : this.maxHeight
      : areaHeight;

    if (this.shrink) {
      return `${Math.min(contentHeight, maxHeight)}px`;
    }

    if (this.grow) {
      return `${maxHeight}px`;
    }

    return `${areaHeight}px`;
  }

  public scrollToBottom(): void {
    this.scrollbar.scrollTo({ bottom: 0, duration: 0 });
  }
}
