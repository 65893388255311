<ng-scrollbar
  #scrollbar
  (scrolled)="scrollDown.emit(null)"
  (scrolledUp)="scrollUp.emit(null)"
  [infiniteScrollDistance]="scrollActivationPercent / 10"
  [infiniteScrollThrottle]="0"
  [infiniteScrollUpDistance]="scrollActivationPercent / 10"
  [scrollWindow]="false"
  [style.height]="scrollAreaHeight"
  cdkScrollable
  class="scrollable-area"
  infiniteScroll
>
  <div
    #content
    class="scrollable-area_content"
  >
    <ng-content></ng-content>
  </div>
</ng-scrollbar>
