export function generateCsv(data: unknown[], customHeaders: string[] | null = null): string {
  let headersRow: string;

  if (customHeaders) {
    headersRow = customHeaders.map((value) => escapeIllegalCharacters(formatValue(value))).join(',') + '\n';
  } else {
    headersRow =
      Object.keys(data[0])
        .map((value) => escapeIllegalCharacters(formatValue(value)))
        .join(',') + '\n';
  }

  const dataRows: string = data
    .map((row) => {
      return Object.values(row)
        .map((value) => escapeIllegalCharacters(formatValue(value)))
        .join(',');
    })
    .join('\n');

  return headersRow + dataRows;
}

function formatValue(value: unknown): string {
  if (value === undefined || value === null || value === '') return '-';
  if (typeof value === 'boolean') return value ? 'TRUE' : 'FALSE';
  if (typeof value === 'object') return JSON.stringify(value);
  return `${value}`;
}

function escapeIllegalCharacters(value: string): string {
  const valueWithEscapedQuotes = value.replace(/"/g, '""');

  const illegalCharacters = [',', '\n', '\r'];
  const containsIllegalCharacters = illegalCharacters.some((char) => valueWithEscapedQuotes.includes(char));

  if (containsIllegalCharacters) return `"${valueWithEscapedQuotes}"`;
  return valueWithEscapedQuotes;
}
